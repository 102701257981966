import { mobiIng, prompt } from '@/shared/fai';
import {
    setProfileInfo,
    setCimg,
    logoutMember,
    getParticipantLotteryListForMember,
    getKnowProductOrderList4member,
    getSubmitList,
    setBulletinReadTime,
    changeReadStatus,
    getIntegralInfo,
    lookCoupon,
    setProfileInfoWithCaptcha,
} from '@/api/member';
import { preprocessUrl } from '@/shared/url';
import { logDog } from '@/shared/log';
import cusHistory from '../cusHistory';
import { changeSelectDefaultColor } from '../method';
import memberHeadPic from './memberHeadPic';
import { MemberFileUtil } from '../fileUpload';
import { getFileType, getPreviewSize } from '@jz/biz-shared';
import { slideshowView } from '@/shared/photo/slides.js';

import { encodeHtml } from '@/shared/util';
import { decodeHtml } from '@/shared/util';
import { JZUpload } from '@jz/biz-shared';
import { getProfileVM } from './eventBus';
import { dayjs } from '@jz/utils';
// 个人资料页面手机区号
export const persInfoChangeMobileCt = function () {
    var mobleCtVal = jm('#modifyPersInfo #mobileCt').val();
    var selectName = jm('#modifyPersInfo #mobileCt')
        .find("option[value='" + mobleCtVal + "']")
        .html();
    if (!mobleCtVal) {
        return;
    }
    jm('#modifyPersInfo .mobileCtVal').html(selectName.substring(selectName.indexOf('+')));
};

export const clickEditMobile = function (dom, signByMobile) {
    if (!signByMobile) {
        jm('#editMobile').show();
        jm(jm(dom).parents('.g_globalLine_wrap')[0]).hide();
    } else {
        jm('#modifyPersInfo').hide();
        jm('#editMobilePanel').show();
    }
};

export const memberProfileSubmit = function (mid, isPsw, needOldPwd, memberAcct) {
    var info = {};
    if (isPsw) {
        var oldPwd = jm('#oldPsw').val();
        if (needOldPwd) {
            if (oldPwd == null || oldPwd == '') {
                mobiIng(LS.memberProfileOldPwdEmpty);
                document.getElementById('oldPsw').focus();
                return;
            }
            info.oldPwd = jm.fai_encrypt(oldPwd);
        }
        var pwd = jm('#newPsw').val();
        if (pwd == null || pwd == '') {
            mobiIng(LS.memberProfilePwdEmpty);
            document.getElementById('newPsw').focus();
            return;
        }
        const pwdReg = new RegExp(
            // eslint-disable-next-line no-useless-escape
            /^(?![\d]+$)(?![a-z]+$)(?![A-Z]+$)(?![`~!@#$%^&_=:;"',\*\(\)\-\+\{\}\[\]\|\\\<\>\.\?\/]+$)[\da-zA-Z`~!@#$%^&_=:;"',\*\(\)\-\+\{\}\[\]\|\\\<\>\.\?\/]{8,20}$/
        );
        if (!pwdReg.test(pwd)) {
            mobiIng(LS.memberSignupRegisterPwdLimit);
            document.getElementById('newPsw').focus();
            return;
        }
        var repwd = jm('#confirmPsw').val();
        if (pwd != repwd) {
            mobiIng(LS.memberProfilePwdNotMatch);
            document.getElementById('confirmPsw').focus();
            return;
        }

        // 新密码与旧密码不能相同
        if (oldPwd && needOldPwd) {
            if (oldPwd.trim() === pwd.trim()) {
                mobiIng(LS.memberProfilePwdSameError);
                document.getElementById('newPsw').focus();
                return;
            }
        }

        // 账密不能相同、也不能逆序相同
        if (memberAcct) {
            if (memberAcct === pwd.trim() || memberAcct === pwd.trim().split('').reverse().join('')) {
                mobiIng(LS.memberProfilePwdAccountSameError);
                document.getElementById('newPsw').focus();
                return;
            }
        }
        info.pwd = jm.fai_encrypt(pwd);
    } else {
        var userEditItemMaxLength = jm('#profileValue').attr('maxlength');
        var userEditItemName = jm('#profileValue').attr('name');
        var userEditItemValue = jm('#profileValue').val();
        info[userEditItemName] = userEditItemValue;
        if (userEditItemValue.length > userEditItemMaxLength) {
            mobiIng(jm.format(LS.memberProfileUserEditItemMaxLength, userEditItemName, userEditItemMaxLength));
            document.getElementById('profileValue').focus();
            return false;
        }
        if (jm('#profileValue').attr('ismust') === 'true') {
            if (userEditItemValue == null || userEditItemValue == '') {
                if (jm(this).is('input')) {
                    mobiIng(jm.format(LS.memberSignupUserAddItemIsEmpty, jm('#modifyProfile').find('.title').html()));
                } else {
                    mobiIng(jm.format(LS.memberSignupUserAddItemIsEmpty2, jm('#modifyProfile').find('.title').html()));
                }
                document.getElementById('profileValue').focus();
                return false;
            }

            if (userEditItemName === 'email' && userEditItemValue.length > 0) {
                if (!jm.isEmail(userEditItemValue)) {
                    mobiIng(jm.format(LS.memberProfileItemCorrect, userEditItemName));
                    document.getElementById('profileValue').focus();
                    return false;
                }
            }
        }
    }

    mobiIng(LS.memberProfileSubmitting, -1);

    jm('.g_button').removeClass('sendIcon');

    const bssKey = 'AP7hkeIECAEQARoEWzE4XSIJ5ZSQ5LiW5rCR';
    window.initFaitest(
        {
            bss: 1,
            bssKey,
        },
        function (checkSign) {
            setProfileInfoWithCaptcha({
                id: mid,
                info: jm.toJSON(info),
                checkSign,
                bssKey,
            })
                .catch(() => {
                    mobiIng(LS.memberProfileError);
                })
                .then((data) => {
                    if (data.success) {
                        mobiIng(LS.memberProfileOK, 1);
                        document.location.reload();
                    } else {
                        jm('.g_button').addClass('sendIcon');
                        jm('.loginIcon ').hide();
                        if (data.rt == -3) {
                            mobiIng(LS.memberProfileOldPwdIncorrectError);
                        } else {
                            mobiIng(data.msg ? data.msg : LS.memberProfileError);
                        }
                    }
                });
        }
    );
};

//修改个人资料
export const setPersonalInfo = async function (mid) {
    var info = {};
    var userEditItemName = '';
    var userEditItemValue = '';
    var userEditItemTitle = '';
    var isCheckUAIStatus = 0;
    var isMustItem = false;
    var newImg = jm('.editHeadPicFooterV3 .saveBtn').attr('newimg');
    var oldImgId = jm('.editHeadPicFooterV3 .saveBtn').attr('oldimgid');

    jm('.infoItem').each(function () {
        userEditItemName = jm(this).attr('name');
        userEditItemValue = jm(this).val();
        userEditItemTitle = jm(this).attr('proptip');
        isMustItem = jm(this).attr('class').indexOf('mustItem') < 0 ? false : true;

        //判断必填项不能空。
        if (!isCheckUAIStatus && isMustItem && (userEditItemValue == null || userEditItemValue == '')) {
            mobiIng(jm.format(LS.memberSignupUserAddItemCorrect, userEditItemTitle));
            this.focus();
            isCheckUAIStatus = 1;
        }
        if (!isCheckUAIStatus && userEditItemName == 'email' && userEditItemValue.length > 0) {
            if (!jm.isEmail(userEditItemValue)) {
                mobiIng(jm.format(LS.memberSignupUserAddItemCorrect, userEditItemTitle));
                this.focus();
                isCheckUAIStatus = 1;
            }
        }
        if (
            !isCheckUAIStatus &&
            userEditItemName == 'mobile' &&
            jm(this).parents('#editMobile').length > 0 &&
            userEditItemValue.length > 0
        ) {
            if (jm(this).attr('disabled') != 'disabled' && jm('#editMobile').css('display') == 'block') {
                if (!jm.isNationMobile(userEditItemValue)) {
                    mobiIng(LS.mobileNumRegular);
                    this.focus();
                    isCheckUAIStatus = 1;
                }
            } else {
                userEditItemValue = '';
            }
        }
        if (userEditItemName == 'mobile' && jm(this).parents('#editMobile').length <= 0) {
            return false;
        }
        info[userEditItemName] = userEditItemValue;
        if (userEditItemName == 'mobile' && userEditItemValue.length <= 0) {
            info['mobileCt'] = '';
        }
    });

    if (jm('#modifyPersInfo .acct').attr('disabled') != '') {
        if (jm('#modifyPersInfo .acct').val() == '') {
            isCheckUAIStatus = 1;
        } else {
            info['acct'] = jm('#modifyPersInfo .acct').val();
        }
    }

    if (Fai.top._openThemeV3) {
        if (newImg != null) {
            try {
                let result = await setCimg({
                    oldImgId,
                    mid,
                    newImg,
                });
                if (result.success) {
                    Fai.top.msgHeadPic.thumbId = result.fileId;
                }
            } catch (e) {
                mobiIng(LS.memberProfileError);
            }
        } else {
            Fai.top.msgHeadPic.thumbId = oldImgId;
        }
        info['headPic'] = Fai.top.msgHeadPic;
    }
    if (isCheckUAIStatus === 0) {
        setProfileInfo({
            id: mid,
            info: jm.toJSON(info),
        })
            .catch(() => {
                mobiIng(LS.memberProfileError);
            })
            .then(async (data) => {
                if (data.success) {
                    await MemberFileUtil.saveMemberFile();
                    mobiIng(LS.memberProfileOK, 1);
                    document.location.reload();
                } else {
                    if (data.rt == -3) {
                        mobiIng(LS.memberProfileOldPwdIncorrectError);
                    } else if (data.rt == -6) {
                        mobiIng(LS.memberAcctExist);
                    } else if (data.rt == -8) {
                        mobiIng(LS.emailPlural);
                    } else if (data.rt == -9) {
                        mobiIng(LS.mobilePlural);
                    } else if (data.mobileErr) {
                        mobiIng(LS.mobileNumRegular);
                    } else {
                        mobiIng(data.msg ? data.msg : LS.memberProfileError);
                    }
                }
            });
    }
};

//主题3.0在会员中心退出会员
export const profileLogoutV3 = function (indexUrl, returnUrl) {
    if (!returnUrl) {
        returnUrl = indexUrl;
    }
    var options = {
        textClass: 'confirm',
        content: LS.onLogoutV3,
        callback() {
            onLogout(preprocessUrl({ path: '', oldPath: '' }) + 'login.jsp?returnUrl=' + jm.encodeUrl(returnUrl));
        },
        insertSelector: '#g_web',
    };

    prompt(options);
};

export const onLogout = function (url) {
    if (url == null) {
        url = 'index.jsp';
    }
    logoutMember().then((result) => {
        if (result.success) {
            location.href = url;
            return;
        }
    });
};

export const modifyListener = function (sessionMid, headPicJson) {
    logDog(201296, 4);
    if (VITE_APP_MODE !== 'visitor') {
        return;
    }
    jm('.modifyArea li').click(function (event, historyCash) {
        if (jm(this).attr('id') == 'myForm') {
            logDog(201296, 5);
        }
        //触发面板返回顶部
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;

        var itemName = jm(this).find('.itemName').html();
        if (jm(this).attr('orderlist') === '0') {
            return;
        }
        if (Fai.top._templateFrameId >= 2000 && Fai.top._templateFrameId < 3000 && !Fai.top._openThemeV3) {
            jm('.form .loginHeader').show();
        }
        if (jm(this).attr('orderlist') === '1') {
            // 最新公告
            changeBackgroundToWhite();
            readBulletin();
            if (Fai.top._profile) {
                jm('#mBulletinConten').find('.title').html(itemName);
            }
            cusHistory.add('latestNews', historyCash, 1, '#latestNews');
            return;
        }

        if (jm(this).attr('id') === 'myForm') {
            if (!Fai.top._profile) {
                itemName = jm(this).find('.itemName span').html();
            }
            loadFormList(itemName);
            return;
        }
        if (jm(this).attr('id') === 'password') {
            //修改密码
            jm('#profile').hide();
            jm('#modifyPsw').show();
            if (Fai.top._profile) {
                jm('#password').find('.title').html(itemName);
            }
            changeBackgroundToWhite();
            cusHistory.add('password', historyCash, 1, '#password');
        } else if (jm(this).attr('id') === 'consigneeInfo') {
            jm('#addrInfoList').find('.title').html(itemName);
            jm('#profile').hide();
            jm('#addrInfoList').show();
            changeBackgroundToWhite();
            cusHistory.add('consigneeInfo', historyCash, 1, '#consigneeInfo');
        } else if (jm(this).attr('id') === 'myreward') {
            jm('#viewMyReward .reward-list').empty(); // 清空dom节点
            jm('#viewMyReward').find('.title').html(itemName);
            jm('#profile').hide();
            jm('#viewMyReward').show();
            //计算reward-list高度 撑满
            let rewardHeight = jm('#g_body').height() - jm('.profileLoginHeader').height(),
                $viewMyReward = jm('#viewMyReward')[0];
            Fai.top.rewardPageNo = 1;
            Fai.top.rewardList = [];
            jm('#viewMyReward')
                .find('.reward-list')
                .css('min-height', rewardHeight + 'px');
            handleRewardScroll($viewMyReward);
        } else if (jm(this).attr('id') === 'personalInfo') {
            //个人资料页
            jm('#modifyPersInfo').find('.title').html(itemName);
            jm('#profile').hide();
            jm('#modifyPersInfo').show();
            if (Fai.top._openThemeV3) {
                initMemberMsgHeadPicV3(Fai.top.msgHeadPic);
                changeSelectDefaultColor('#c4c9ca', '#333333', '.jz_themeV3 #modifyPersInfo select');
                changeSelectDefaultColor('#c4c9ca', '#333333', '.jz_themeV3 #modifyPersInfo .mobileLine');
                initPersInfo();
                if (!Fai.top._profile) {
                    selectOverflow(Fai.top.selectAry);
                }
            }
            changeBackgroundToWhite();
            cusHistory.add('personalInfo', historyCash, 1, '#personalInfo');
            // 强制重绘 使得头像以正确的样式渲染
            setTimeout(() => {
                jm('.memberMsgHeadPicV3').hide();
                jm('.memberMsgHeadPicV3').show();
            }, 0);
        } else if (jm(this).attr('id') === 'cutPrice') {
            jm('#modifyCutPrice').find('.title').html(itemName);
            jm('#profile').hide();
            jm('#modifyCutPrice').show();
            changeBackgroundToWhite();
            cusHistory.add('cutPrice', historyCash, 1, '#cutPrice');
        } else if (jm(this).attr('id') === 'groupBuy') {
            logDog(200891, 1);
            jm('#modifyGroupBuy').find('.title').html(itemName);
            jm('#profile').hide();
            jm('#modifyGroupBuy').show();
            changeBackgroundToWhite();
            cusHistory.add('groupBuy', historyCash, 1, '#groupBuy');

            logDog(200891, 2);
        } else if (jm(this).attr('id') === 'promoter') {
            changeBackgroundToWhite();
            cusHistory.add('promoter', historyCash, 1, '#promoter');
            return;
        } else if (jm(this).attr('id') === 'dist') {
            changeBackgroundToWhite();
            cusHistory.add('dist', historyCash, 1, '#dist');
            return;
        } else if (jm(this).attr('id') === 'integral') {
            jm('#modifyIntegral').find('.title').html(itemName);

            jm('#profile').hide();
            jm('#modifyIntegral').show();
            changeBackgroundToWhite();
            cusHistory.add('integral', historyCash, 1, '#integral');

            logDog(200751, 8);
        } else if (jm(this).attr('id') === 'collection') {
            jm('#modifyCollection').find('.title').html(itemName);

            jm('#profile').hide();
            jm('#modifyCollection').show();
            changeBackgroundToWhite();
            cusHistory.add('collection', historyCash, 1, '#collection');
        } else if (jm(this).attr('id') === 'coupon') {
            jm('#modifyCoupon').find('.title').html(itemName);
            jm('#profile').hide();
            jm('#modifyCoupon').show();
            cusHistory.add('coupon', historyCash, 1, '#coupon');
            //置回优惠券红点提示
            if (Mobi.isGiveCoupon) {
                logDog(201475, 3);
                Mobi.isGiveCoupon = false;
                jm('#coupon').find('.couponTips').hide();
                var memberPropertyArea = jm('#memberPropertyArea');
                if (memberPropertyArea.length > 0) {
                    memberPropertyArea.find('.memberCouponTips').hide();
                }
                lookCoupon();
            }
        } else if (jm(this).attr('id') === 'changeOtherLoginAcct') {
            changeBackgroundToWhite();
            if (Fai.top._profile) {
                jm('#bindOtherAcct').find('.title').html(itemName);
            }
            return;
        } else if (jm(this).attr('id') === 'knowPayment') {
            itemName = jm(this).find('.itemName span').html();
            jm('#knowPayList').find('.title').html(itemName);
            jm('#profile').hide();
            jm('#knowPayList').show();
            changeBackgroundToWhite();
            loadKnowPayList();
        } else {
            const id = jm(this).attr('id');
            const notHideArr = ['memberShipcard']; // 会员卡(ios下不能隐藏#profile)
            const isHide = !notHideArr.includes(id); // 不是来自会员卡的页面
            var itemText = jm(this).find('.itemText').html();
            var isiOS = !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端

            // ==> 在ios里面，history返回的页面是缓存的页面，而不是重新加载。
            (!isiOS || isHide) && jm('#profile').hide(); // ios下，如果是属于上述notHideArr的范围，就不能隐藏

            jm('#modifyProfile').show();
            jm('#modifyProfile').find('.title').html(itemName);
            jm('#profileValue').val(decodeHtml(itemText));
            jm('#profileValue').attr('name', id);
            jm('#profileValue').attr('ismust', jm(this).attr('ismust'));
            revertBackground();
        }
    });

    // 3.0会员中心页面点击头像跳转到个人资料页面
    jm('.J_memberJumpToMsg').click(function (event, historyCash) {
        jm('#modifyPersInfo').find('.title').html('个人资料');
        jm('#profile').hide();
        jm('#modifyPersInfo').show();
        if (Fai.top._openThemeV3) {
            initMemberMsgHeadPicV3(Fai.top.msgHeadPic);
            changeSelectDefaultColor('#c4c9ca', '#333333', '.jz_themeV3 #modifyPersInfo select');
            changeSelectDefaultColor('#c4c9ca', '#333333', '.jz_themeV3 #modifyPersInfo .mobileLine');
            initPersInfo();
            if (!Fai.top._profile) {
                selectOverflow(Fai.top.selectAry);
            }
        }
        changeBackgroundToWhite();
        cusHistory.add('memberHeadPicV3', historyCash, 1, '#memberHeadPicV3');
        // 强制重绘 使得头像以正确的样式渲染
        setTimeout(() => {
            jm('.memberMsgHeadPicV3').hide();
            jm('.memberMsgHeadPicV3').show();
        }, 0);
    });

    var hp = new memberHeadPic(sessionMid, headPicJson);
    hp.init();
    cusHistory.init();
    if (jm.getCookie('showIntegral') == 'true') {
        jm('#integral').click();
        jm.cookie('showIntegral', '', { path: '/' });
    }

    //我的优惠券红点提示
    var myCoupon = jm('#coupon');
    if (myCoupon.length > 0 && Mobi.isGiveCoupon) {
        var wrap = myCoupon.find('.itemName');
        wrap.append("<span class='couponTips'></span>");
    }
    //会员资产优惠券红点提示
    var memberProperty = jm('#memberPropertyArea');
    if (memberProperty.length > 0 && Mobi.isGiveCoupon) {
        let wrap = memberProperty.find('.memberPropertyItem');
        wrap.each(function () {
            var itemName = jm(this).find('.memberPropertyItemName');
            if (itemName.text() == '我的优惠券') {
                jm(this).append("<span class='memberCouponTips'></span>");
            }
        });
    }
};

// 3.0公告页面、修改密码、个人资料页面底色为白色
const changeBackgroundToWhite = function () {
    jm('.jz_themeV3.mobiCol12 #g_web').css('background-color', '#fff');
    jm('.jz_themeV3.mobiCol12 .middleCenter').css('padding-bottom', '0');
    jm('.jz_themeV3.mobiCol12 .formMiddleContent').css('background-color', '#ffffff');
};

// 3.0恢复其他页面底色
export const revertBackground = function () {
    jm('.jz_themeV3.mobiCol12 #g_web').css('background-color', '#f7f7f7');
    jm('.jz_themeV3.mobiCol12 .middleCenter').css('padding-bottom', '0');
};

// 主题3.0个人资料页面会员头像
const initMemberMsgHeadPicV3 = function (options) {
    var img = jm('.memberMsgHeadPicV3'),
        icon = jm('.icon-pnameIcon');
    if (typeof options.path != 'undefined') {
        img.attr('src', options.path);
    }
    if (options.width != 0 && options.imgW > 0 && options.imgH > 0) {
        var scale = options.width / icon.width(),
            marginTop = -(options.top / scale) + 'px',
            marginLeft = -options.left / scale + 'px';
        img.css({
            width: options.imgW / scale + 'px',
            height: options.imgH / scale + 'px',
            marginTop,
            marginLeft,
        });
        if (options.imgW / scale == 0 || options.imgH / scale == 0) {
            img.removeAttr('style');
        }
    }
};

// 点击个人资料时候，个人资料内页头像样式
const initPersInfo = function () {
    // jm('#modifyPersInfo #memberHeadPicV3').css({"width":"3rem","height":"3rem"});
    jm('#modifyPersInfo .member_msg_head_pic_v3').css('border', 'none');
};

// 会员中心-我的奖品
export const loadMyRewardList = function (pageNo = 1, pageSize = 10) {
    jm('#webLoading').show();
    let $rewardList = jm('#viewMyReward .reward-list');
    getParticipantLotteryListForMember(pageNo, pageSize)
        .catch((err) => {
            console.log(err);
        })
        .then((resultJson) => {
            jm('#webLoading').hide();
            let list = resultJson.list,
                listHtmlStr = createRewardListHtmlStr(list, pageNo, pageSize),
                totalPage = Math.ceil(resultJson.totalSize / pageSize);
            Fai.top.rewardList = Fai.top.rewardList.length > 0 ? Fai.top.rewardList.concat(list) : list;
            if (jm('#viewMyReward').length > 0) {
                jm('#viewMyReward')[0].totalPage = totalPage;
            }
            $rewardList.append(listHtmlStr);
            // 查看中奖详情, 绑定一次就好
            if (Fai.top.rewardPageNo == 1) {
                $rewardList.on('click', '.reward-item', function (e) {
                    let index = e.target.dataset.index,
                        item = Fai.top.rewardList[index].prize;
                    // 存在该实例，return
                    if (Mobi.ResultPopup.vm) return;
                    Mobi.ResultPopup.render(item, item);
                });
            }
        });
    function timestampToTime(timestamp) {
        return dayjs(timestamp).format('YYYY-MM-DD HH:mm:ss');
    }

    function createRewardListHtmlStr(list, pageNo, pageSize) {
        let rewardListStr = '';
        if (list == null) list = [];
        let length = list.length;
        if (length > 0) {
            list.forEach((item, index) => {
                let itemIndex = index + 1 + (pageNo - 1) * pageSize - 1;
                let itemStr = `
                  <li data-index=${itemIndex} class='reward-item'>
                      <div data-index=${itemIndex} class='reward-title'>${
                    item.prize.type == 2 ? encodeHtml(item.prize.pInfo.name) : encodeHtml(item.prize.name)
                }</div>
                      <div data-index=${itemIndex} class='reward-date'>${timestampToTime(item.createTime)}</div>
                  </li>
              `;
                rewardListStr += itemStr;
            });
        } else if (pageNo <= 1) {
            rewardListStr = `
              <div class='empty-wrap'>
                  <div class='empty-list'></div>
                  <div class='empty-text'>无中奖的奖品</div>
              </div>
          `;
        } else {
            pageNo--;
        }

        return rewardListStr;
    }
};

async function renderKnowPayListHtmlStr(list) {
    const {
        data: { existAftersaleOrder, wxpayAutoRefund },
    } = await jzRequest.get('/ajax/product_h.jsp', {
        params: {
            cmd: 'getWafNotCk_getKonwProductOrderExt',
        },
    });

    getProfileVM().existAftersaleOrder = existAftersaleOrder;
    getProfileVM().wxpayAutoRefund = wxpayAutoRefund;
    getProfileVM().knowList = list;
}
/* 知识付费 */
export const loadKnowPayList = function () {
    getKnowProductOrderList4member()
        .catch((err) => {
            console.log(err);
        })
        .then((resultJson) => {
            renderKnowPayListHtmlStr(resultJson.list);
        });
};

export const loadFormList = function (itemName) {
    let $formList = jm('#formList');
    // 列表容器
    let $listContainer = $formList.find('.area');

    let formList;

    getSubmitList()
        .catch(() => {
            mobiIng(LS.js_systemError);
        })
        .then((data) => {
            let themeColorClass = Fai.top._openThemeV3 ? 'g_main_color_v3' : ' g_mainColor';
            if (data.success) {
                formList = data.pageParam.siteFormSubmitList;
                // 列表DOM字符串
                let listStr = '';
                let formListItemStr = '';
                const isOpenStatus = true;
                const statusUpId = data.pageParam.statusUpId;
                jm('#formList').attr('_statusUpId', statusUpId);
                formList.forEach(
                    ({ name, createTime: date, id, haveRead, showRemark, formStatusName, showFormStatus }) => {
                        showFormStatus = isOpenStatus && showFormStatus;
                        date = timestamp2FormatDate(date);
                        formListItemStr =
                            `
                          <li class="item" _id = ${id} _showRemark = ${showRemark}>
                            <div class="form_name">` +
                            encodeHtml(name) +
                            `
                              ${showRemark && !haveRead ? "<div class='remind'></div>" : ''}
                            </div>
                            <div class="form_date">${date} ${
                                showFormStatus ? `<sapn class='${themeColorClass}'>${formStatusName}</span>` : ''
                            }</div>
                        </li>
                      `;
                        listStr += formListItemStr;
                    }
                );
                if (formList.length === 0) {
                    let noFormRecordText = jm('#myForm').attr('_noFormRecord');
                    listStr = `
              <div class="empty_tips">
                <div class="img"></div>
                <div class="text">${noFormRecordText}</div>
              </div>
              `;
                }
                $listContainer.empty();
                $listContainer.append(listStr);
                jm('#formList').find('.title').html(itemName);
                jm('#profile').hide();
                changeBackgroundToWhite();
                $formList.show();
            }
        });
};

function timestamp2FormatDate(timestamp) {
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`;
}

// 奖品列表分页加载
const handleRewardScroll = function ($ele) {
    Fai.top.nowTime = Date.now();
    loadMyRewardList();
    jm(window).on('scroll', function () {
        let $window = jm(window)[0],
            scrollY = $window.scrollY,
            windowHeight = $window.innerHeight,
            eleHeight = $ele.offsetHeight;
        if (scrollY + windowHeight >= eleHeight) {
            if (Fai.top.rewardPageNo <= jm('#viewMyReward')[0].totalPage) {
                const now = Date.now();
                if (now - Fai.top.nowTime < 800) {
                    return;
                }
                Fai.top.nowTime = Date.now();
                Fai.top.rewardPageNo++;
                loadMyRewardList(Fai.top.rewardPageNo);
            }
        }
    });
};

// 个人资料页面，为下来框赋值
const selectOverflow = function (selectAry) {
    var id = '';
    var value = '';
    // 为select初始化赋值
    for (var i = 0; i < selectAry.length; i++) {
        id = selectAry[i].id;
        value = selectAry[i].value;
        jm('#' + id)
            .siblings('.selectCover')
            .val(value);
        // 初始化如果数值为空，则显示placeholder
        if (value == null || value == '') {
            var placeholder = jm('#' + id)
                .siblings('.customIcon')
                .text()
                .trim();
            jm('#' + id)
                .siblings('.selectCover')
                .attr('placeholder', LS.mobi_member_PleaseInput + placeholder);
        }
    }

    // 监听select值改变
    jm('.profile_content_v3 select').each(function () {
        if (jm(this).attr('name') == 'mobileCt') {
            return;
        }
        jm('.selectCover + select').change(function () {
            var selectedOption = jm(this)[0].value;
            jm(this).siblings('.selectCover').val(selectedOption);
            // 如果内容为空，则显示placeholder
            if (selectedOption == null || selectedOption == '') {
                var placeholder = jm(this).siblings('.customIcon').text().trim();
                jm(this)
                    .siblings('.selectCover')
                    .attr('placeholder', LS.mobi_member_PleaseInput + placeholder);
            }
        });
    });
};

const readBulletin = function () {
    var bulletinTitle = jm('#bulletinTitle'),
        id = jm('#bulletinTitle').attr('sessionMid');
    setBulletinReadTime(id)
        .catch(() => {
            mobiIng(LS.js_systemError);
        })
        .then((result) => {
            if (result.success) {
                jm('#profile').hide();
                jm('#mBulletinConten').show();
                jm(bulletinTitle).removeClass('newsReminds');
                //应测试要求，Mobi端的会员公告的图片适应手机屏幕
                jm('#mBulletinConten img').each(function () {
                    var width = jm('#mBulletinConten p').width();
                    if (jm(this).attr('width') >= width) {
                        var height = jm(this).attr('height') / (jm(this).attr('width') / width);
                        jm(this).css({
                            height: height + 'px',
                            width: width + 'px',
                        });
                    }
                });
            } else {
                mobiIng(LS.js_systemError);
            }
        });
};

//  保存文件上传的需要的数据
export const initMemberUploadImg = function (upImgMaxSize, imgType) {
    mobiMemberUploadImgHtml('file_upload', upImgMaxSize, imgType);
    //延迟头像图片的加载
    var src = jm('#headPic').attr('_src');
    var img = new Image();
    img.src = src;

    jm('#headPic').attr('src', src);
};

const mobiMemberUploadImgHtml = function (placeHolderId, fileSize, fileTypeString) {
    var fileTypeList = fileTypeString.split(',');

    JZUpload.initUploadifyButton({
        dom: `#${placeHolderId}`,
        fileTypeExts: fileTypeList.join(';'),
        onChange: async (e) => {
            let file = e.target.files[0];
            let jzUpload = new JZUpload({
                getAccessKeyUrl: '/api/manage/advanceUpload/genAccessKey',
                onError(error) {
                    mobiIng(LS.mobiFormSubmitFileUploadFile + file.name + '   ' + error.message);
                },
                // 取决于后台
                onComplete(data) {
                    setTimeout(function () {
                        mobiIng(jm.format(LS.mobiFormSubmitFileUploadSucess, encodeHtml(file.name)), true);
                    }, 1800);
                    //上传成功，显示图片
                    reloadMemberImg('new', data);
                },
                onNext(info) {
                    const percent = Math.ceil(info.total.percent);
                    if (isNaN(percent)) {
                        return;
                    }
                    if (percent == 100) {
                        mobiIng(LS.mobiFormSubmitFileUploadIng + percent + '%', true);
                    } else {
                        mobiIng(LS.mobiFormSubmitFileUploadIng + percent + '%', false);
                    }
                },
                // faiupload的params
                params: {
                    aid: Fai.top._aid,
                    folderId: Fai.top._siteId,
                    bssInfo: {
                        siteId: Fai.top._siteId || 0,
                        fileSizeLimit: fileSize,
                        minWidth: 100,
                    },
                    accessKeyInfo: {
                        isTmp: true,
                    },
                },
                // 必填，用于内部业务提示
                tipsFn(string) {
                    mobiIng(string, true);
                },
                fileTypeExts: fileTypeList.join(';'),
            });
            jzUpload.upload(file);
        },
    });

    jm('#' + placeHolderId)
        .parent('.modifyBtn')
        .on('click', function () {
            jm(this).find('.selectbtn')[0].click();
        });
};

const reloadMemberImg = function (flag, data) {
    var headPic = jm('#headPic'),
        area = jm('.picArea'),
        areaH = Number(area.height()),
        areaW = Number(area.width());
    if (flag == 'new') {
        var img = new Image();
        img.src = data.smallPath;
        img.onload = function () {
            headPic.attr('src', data.smallPath);
            let imgTop = (areaH - headPic.height()) / 2;
            let imgLeft = (areaW - headPic.width()) / 2;
            headPic.css({ top: imgTop + 'px', left: imgLeft + 'px' });
            jm('.editHeadPicFooter .saveBtn').attr('newimg', jm.toJSON(data));
        };
    }
};

export const memberModuleReturnUrlInit = function (indexUrl, returnUrl) {
    if (!returnUrl) {
        returnUrl = indexUrl;
    }

    jm('#profile').find('.loginHeader .g_close').attr('href', returnUrl);
};

export const setMyFormTipState = function (remarkUpdateTips, statusUpdateTips) {
    const haveReadStatus = false;
    let unreadFormListItems = jm('#formList').find('.area li .remind');
    let $remid = jm('#myForm .itemName .remind');
    let $updateTips = jm('#myForm .clickIcon .update_tips');
    let updateTipsText = haveReadStatus ? statusUpdateTips : remarkUpdateTips;
    if (unreadFormListItems.length === 0) {
        $remid.hide();
        $updateTips.hide();
    } else {
        $remid.css('display', 'inline-block');
        $updateTips.text(updateTipsText);
        $updateTips.show();
    }
};

//在会员中心退出会员
export const profileLogout = function (indexUrl, returnUrl) {
    if (!returnUrl) {
        returnUrl = indexUrl;
    }
    var url = preprocessUrl({ path: '', oldPath: '' });
    var options = {
        textClass: 'confirm',
        content: LS.onLogout,
        callback() {
            onLogout(url + 'login.jsp?returnUrl=' + jm.encodeUrl(returnUrl));
        },
        insertSelector: '#g_body',
    };

    prompt(options);
};

const currentFileIcon = (fileType = 'file') => {
    let result = '#jzm-v17';
    switch (fileType) {
        case 'pdf':
            result = '#jzm-v11';
            break;
        case 'exls':
            result = '#jzm-v6';
            break;
        case 'gzip':
            result = '#jzm-v8';
            break;
        case 'music':
            result = '#jzm-v7';
            break;
        case 'ppt':
            result = '#jzm-v12';
            break;
        case 'txt':
            result = '#jzm-v13';
            break;
        case 'word':
            result = '#jzm-v15';
            break;
        case 'video':
            result = '#jzm-v14';
            break;
        case 'image':
            result = '#jzm-v9';
            break;

        default:
            break;
    }
    return result;
};

// 从 initFormItemEvent 抽取出来
export const showRenderDetail = function ($item, options = {}) {
    const { remarkLabel, noRemarkMessageText, memberCurrentStatus, haveRead } = options;

    const payMap = {
        17: {
            payType: '微信支付',
            payIcon: '#jzm-vicon_wechat_pay',
            payClass: 'wxpay_icon',
        },
        19: {
            payType: '支付宝',
            payIcon: '#jzm-vicon_alipay',
            payClass: 'alipay_icon',
        },
        20: {
            payType: '支付宝',
            payIcon: '#jzm-vicon_alipay',
            payClass: 'alipay_icon',
        },
        7: {
            payType: 'PayPal',
            payIcon: '#jzm-vicon_paypal',
            payClass: 'alipay_icon',
        },
    };

    let themeColorClass = Fai.top._openThemeV3 ? 'g_main_color_v3' : ' g_mainColor';
    let isOpenFormRemark = $item.attr('_showRemark') === 'true';
    let $clickFormListItem = $item;
    let option = {};
    let id = $clickFormListItem.attr('_id');
    option['id'] = id;
    option['callBackData'] = true;
    if (isOpenFormRemark) {
        option['haveRead'] = true;
    }
    if (haveRead !== undefined) {
        option['haveRead'] = haveRead;
    }

    changeReadStatus(option)
        .catch(() => {
            mobiIng(LS.js_systemError);
        })
        .then((data) => {
            let $formDetail = jm('#formDetail');
            let $formContainer = $formDetail.find('.profileContent');
            if (data.success) {
                // set form detail data
                window.$store.commit('form/editFormDetail', {
                    formId: data.siteFormInfo.id,
                    submitResultId: id,
                    openMemberModifySubmit: data.submitInfo?.openMemberModifySubmit ?? false,
                });
                window.$store.commit('form/setFormDetail', {
                    id,
                    detail: {
                        formInfo: data.siteFormInfo,
                        submitInfo: data.submitInfo,
                    },
                });
                let formLabelList = data?.siteFormInfo?.contentList ?? [];
                let formItemValueList = data?.submitInfo?.submitContentList ?? [];
                const fileList = data?.submitInfo?.siteFormFileList ?? [];
                let listStr = '';

                if (data.siteFormInfo.submitShowId) {
                    listStr += `
                        <div class="item  curStatus">
                            <div>
                                ${LS.form}ID:
                                <span>${data.submitInfo.id}</span>
                            </div>
                        </div>
                    `;
                }

                const isOpenStatus = true;
                if (isOpenStatus) {
                    //  审核状态
                    const isShowStatus = data.siteFormInfo.showFormStatus;
                    const curStatusText = data.submitInfo.formStatusName;
                    if (isShowStatus) {
                        let curStatusLabel = `${memberCurrentStatus}：`;
                        let statusStr = `
                                <div class="item  curStatus">
                                    <div >
                                        ${curStatusLabel}
                                        <span class='${themeColorClass}'>${curStatusText}</span>
                                    </div>
                                </div>
                            `;
                        listStr += statusStr;
                    }
                }

                if (isOpenFormRemark) {
                    let remark = data.submitInfo.remark;
                    const isEmptyRemark = remark === '';
                    isEmptyRemark && (remark = noRemarkMessageText);
                    const remarkClass = isEmptyRemark ? 'empty_remark_tips' : '';
                    let remarkStr =
                        `
                    <div class="item">
                        <div class="name">
                            ` +
                        encodeHtml(remarkLabel) +
                        `:
                            <div class="remind"></div>
                        </div>
                        <div class="data ${remarkClass}">` +
                        encodeHtml(remark) +
                        `</div>
                    </div>
                    `;
                    listStr += remarkStr;
                }
                let formListItemStr = '';
                // 这里根据规则来进行显示
                let rules = data.siteFormInfo.other.rules;
                let isShowContentIdList = []; // 用于 rule中的showList 的表单项 id
                let contentIdShowMap = {}; // 用于判断表单项是否显示、隐藏
                let ruleContainerArr = []; // 存储 rule 项的 contentId

                const imageMap = new Map();
                const picMap = new Map();

                rules.forEach((rule) => {
                    if (ruleContainerArr.indexOf(rule.contentId) == -1) {
                        ruleContainerArr.push(rule.contentId);
                    }
                    rule.showList.forEach((showItem) => {
                        if (isShowContentIdList.indexOf(showItem.key) == -1) {
                            isShowContentIdList.push(showItem.key);
                        }
                    });
                });

                // 没有规则的直接显示，有规则的隐藏
                formLabelList.forEach((label) => {
                    if (isShowContentIdList.indexOf(label.id) == -1) {
                        contentIdShowMap[label.id] = true;
                    } else {
                        contentIdShowMap[label.id] = false;
                    }
                });

                // 根据值来判断哪些规则项是可以显示的
                formItemValueList.forEach((valueItem) => {
                    let contentItem = formLabelList.find((label) => label.id == valueItem.id) || {};
                    if (contentItem.type == 2 || contentItem.type == 3 || contentItem.type == 4) {
                        if (ruleContainerArr.indexOf(contentItem.id) != -1) {
                            let inputArr = contentItem.input.split('\n');
                            let selected = inputArr.indexOf(valueItem.val);
                            let contentId = contentItem.id;

                            let findRule = rules.find(
                                (rule) => rule.contentId == contentId && rule.selected == selected
                            );
                            if (findRule != null) {
                                findRule.showList.forEach((showItem) => {
                                    contentIdShowMap[showItem.key] = true;
                                });
                            }
                        }
                    }
                });

                for (let i = 0; i < formLabelList.length; i++) {
                    let formLabelItem = formLabelList[i];
                    let formLabel = formLabelItem.name;
                    let formItemType = formLabelItem.type;
                    const Text_INTRO_TYPE = 5;
                    const FILE_UPLOAD = 7;
                    const PHONE = 8;
                    let formLabelId = formLabelItem.id;
                    let formItemValue;

                    if (!contentIdShowMap[formLabelItem.id]) continue;

                    if (formItemType === Text_INTRO_TYPE) {
                        formLabel = formLabelItem.headline;
                        formItemValue = formLabelItem.input;
                    } else {
                        formItemValue = formItemValueList.find((formItemValue) => formItemValue.id === formLabelId);
                        if (formItemValue === undefined) {
                            formItemValue = '';
                        } else {
                            if (formItemType === PHONE) {
                                formItemValue = formItemValue.phoneText ?? formItemValue.val;
                            } else {
                                formItemValue = formItemValue.val;
                            }
                        }
                    }

                    if (formItemType === FILE_UPLOAD) {
                        let fileContentHtml = '<div class="item_file_container"> <ul class="item_file_list">';
                        const previewList = [];
                        const currentFileList = fileList.filter((value) => formItemValue.includes(value.id));
                        for (let file of currentFileList) {
                            const { name, thumbUrl, size, id, previewUrl } = file;
                            const fileType = getFileType(name);
                            const iconClass = currentFileIcon(fileType);
                            const previewSize = getPreviewSize(size);
                            const svgClass = `item_file_item_iconbox--${fileType}`;
                            const isImage = fileType === 'image';
                            const $iconHtml = isImage
                                ? `<img class="${svgClass} item_file_item_iconbox--icon" src="${thumbUrl}" alt="${name}"></img>`
                                : `<i class="item_file_item_icon--bg item_file_item_iconbox--icon">
                                <svg class="file_icon ${svgClass}">
                                    <use xlink:href="${iconClass}"/>
                                </svg>
                            </i>`;
                            fileContentHtml += `<li data-id="${formLabelId}" data-file="${id}" class="item_file_item">
                                <div class="item_file_item_iconbox">
                                    ${$iconHtml}
                                </div>
                                <div class="item_file_item_textbox">
                                    <p class="item_file_item_title">${name}</p>

                                    <p  class="item_file_item_des">${previewSize}</p>
                                </div>
                            </li>`;
                            if (isImage) {
                                previewList.push({
                                    src: previewUrl ?? thumbUrl,
                                    id,
                                    title: name,
                                });
                            }
                        }

                        imageMap.set(formLabelId, {
                            previewList,
                        });
                        fileContentHtml += '</ul></div>';
                        formListItemStr = `
                            <div class="item">
                                <div class="name">
                                ${encodeHtml(formLabel)}
                                    <div class="remind"></div>
                                </div>
                                ${fileContentHtml}
                            </div>
                        `;
                    } else if (formItemType === 14 || formItemType === 15) {
                        let picList = [];
                        try {
                            picList = JSON.parse(formItemValue);
                        } catch (e) {
                            console.log(e);
                        }
                        picMap.set(formLabelId, {
                            previewList: picList,
                        });
                        formListItemStr = `
                            <div class="item">
                                <div class="name">${encodeHtml(formLabel)}<div class="remind"></div>
                                </div>
                                ${
                                    picList.length !== 0
                                        ? `<div  class="item_radio_pic" >
                                    ${picList
                                        .map(
                                            (pic) => `
                                        <div
                                            class="radio_pic_item"
                                        >
                                            <div data-id="${formLabelId}" data-file="${pic.id}" class="radio_pic_item_pic" style="background-image:url(${pic.src})"></div>
                                            <div  class="radio_pic_item_name">${pic.name}</div>
                                        </div>
                                    `
                                        )
                                        .join('')}
                                </div>`
                                        : `<div class="data"></div>`
                                }
                            </div>
                        `;
                    } else {
                        formListItemStr = `
                            <div class="item">
                                <div class="name">${encodeHtml(formLabel)}<div class="remind"></div>
                                </div>
                                <div class="data">${encodeHtml(formItemValue)}</div>
                            </div>
                        `;
                    }
                    listStr += formListItemStr;
                }

                const {
                    payInfo: { payDescText, payMoney, isPay, openOnlinePay },
                } = data;
                const payMode = data.submitInfo.payMode;
                const payModeInfo = payMap[payMode] || payMap[17];

                const payStatus = isPay ? LS.havePaid : LS.nonPayment;

                // const themeColorClass   = Fai.top._openThemeV3 ? "g_main_color_v3": "g_oldThemeColor"

                // 表单支付
                const payIcon =
                    Number(payMoney) > 0
                        ? `
                    <div class="pay_type_wrap">
                        <svg class=${payModeInfo.payClass}>
                            <use xlink:href=${payModeInfo.payIcon} />
                        </svg class="wx_pay_text">
                        <span style="font-size: 0.6rem;">${payModeInfo.payType}</span>
                    </div>
                `
                        : '';
                if (openOnlinePay) {
                    let formPayStr = `
                <div class="pay_wrap" >
                    <span style="font-size: 0.6rem;margin-right: 0.24rem;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;display: inline-block;">${payDescText}:</span>
                    <div style="flex-shrink: 0;font-size: 0;">
                        <span class="${themeColorClass}" style="font-size: 0.5rem;font-weight: bold;">￥</span>
                        <span class="${themeColorClass}" style="font-size: 0.65rem;font-weight: bold;">${
                        splitNumberWithDot(payMoney)[0]
                    }.</span>
                        <span class="${themeColorClass}" style="font-size: 0.5rem;font-weight: bold;">${
                        splitNumberWithDot(payMoney)[1]
                    }</span>
                        <span style="margin-right: 0.24rem;font-size: 0.65rem;">(${payStatus})</span>
                    </div>
                    ${payIcon}
                </div>
            `;
                    listStr += formPayStr;
                }

                let $remind = $clickFormListItem.find('.remind');
                $remind && $remind.remove();
                let formName = data.siteFormInfo.name;
                jm('#formDetail').find('.title').text(formName);
                jm('#formList').hide();
                $formContainer.empty();
                $formContainer.append(listStr);
                $formContainer.find('.item_file_list').on('click.prevImage', '.item_file_item', function (e) {
                    if (jm(e.target).hasClass('item_file_item_iconbox--image')) {
                        const id = parseInt(jm(this).data('id'));
                        const currentImageData = imageMap.get(id);
                        const fileId = jm(this).data('file');
                        if (currentImageData?.previewList) {
                            let initialIndex = 0;
                            currentImageData?.previewList?.forEach((imageData, index) => {
                                if (imageData?.id === fileId) {
                                    initialIndex = index;
                                }
                            });
                            slideshowView({
                                id: `slideshowView_${id}_${fileId}`,
                                previewSrcList: currentImageData?.previewList,
                                initialIndex,
                                logEventConfig: {
                                    event: 'jz_hdpzj',
                                    properties: {
                                        jz_content_terminal: 'mobi-会员中心-表单详情',
                                        jz_version: Fai.top.jzVersion,
                                    },
                                },
                            });
                        }
                    }
                });
                $($formContainer[0])
                    .find('.item_radio_pic')
                    .on('click.prevImage', '.radio_pic_item_pic', function () {
                        const id = parseInt(jm(this).data('id'));
                        const currentImageData = picMap.get(id);
                        const fileId = jm(this).data('file');
                        if (currentImageData?.previewList) {
                            let initialIndex = 0;
                            currentImageData?.previewList?.forEach((imageData, index) => {
                                if (imageData?.id == fileId) {
                                    initialIndex = index;
                                }
                            });
                            slideshowView({
                                id: `slideshowView_${id}_${fileId}`,
                                previewSrcList: currentImageData?.previewList,
                                initialIndex,
                                logEventConfig: {
                                    event: 'jz_hdpzj',
                                    properties: {
                                        jz_content_terminal: 'mobi-会员中心-表单详情',
                                        jz_version: Fai.top.jzVersion,
                                    },
                                },
                            });
                        }
                    });
                //显示列表
                $formDetail.show();
            }
        });
};

export const initFormItemEvent = function (remarkLabel, noRemarkMessageText, memberCurrentStatus) {
    jm('#formList .area')
        .off('click.form')
        .on('click.form', '.item', function () {
            logDog(201296, 6);
            showRenderDetail(jm(this), {
                remarkLabel,
                noRemarkMessageText,
                memberCurrentStatus,
            });
        });

    changeBackgroundToWhite();
};

function splitNumberWithDot(number) {
    if (typeof number !== 'number') {
        throw new Error('请传入数字类型参数');
    }

    // 返回值为数组 [a, b] a为整数部分 b为小数部分，这部分对自动补0
    let numStr = number.toString();

    // 划分为整数部分和小数部分  小数部分不存在时值为""，此时对其进行自动补零即可
    let [iPart, fPart = ''] = numStr.split('.');

    // 小数指定长度
    const DEFAULT_FLOAT_LENGTH = 2;

    const fPartLength = fPart.length;

    let paddingLength = DEFAULT_FLOAT_LENGTH - fPartLength;
    // 补零
    while (paddingLength--) {
        fPart += '0';
    }
    // 有序返回
    return [iPart, fPart];
}

// 会员中心积分详情
export const loadItg = function (mid, itgName, itgRuleName, showItgRuleBtn) {
    if (mid <= 0) {
        location.href = preprocessUrl({ path: '', oldPath: '' }) + 'login.jsp?errno=11';
    }

    // 打印基本结构
    var rootNode = jm('.itgPanel');

    var html =
        '' +
        "<div class='whole_top'>" +
        "<div class='top_container webHeaderBg'>" +
        "<img style='position:absolute;top:0;' src='" +
        Fai.top._resImageRoot +
        "/image/v2/integralHead.png'></img>" +
        "<div class='credit_points'></div>" +
        "<div class='widget'>" +
        itgName +
        '</div>';
    if (showItgRuleBtn) {
        html += "<div id='itgRuleForward' class='itgRuleForward'>" + encodeHtml(itgRuleName) + '</div>';
    }
    html +=
        '</div>' +
        "<div class='nav_container'>" +
        "<div class='nav'>" +
        "<div class='all item'>" +
        LS.productScreenAll +
        '</div>' +
        "<div class='income item'>" +
        LS.itg_income +
        '</div>' +
        "<div class='spend item'>" +
        LS.itg_spend +
        '</div>' +
        '</div>' +
        "<div class='underline g_bgColor'></div>" +
        '</div>' +
        '</div>' +
        "<div class='no_itg'>" +
        "<div class='img'></div>" +
        "<div class='txt'>" +
        LS.itg_notYet +
        ' ' +
        itgName +
        '~</div>' +
        '</div>' +
        "<div class='list'>" +
        '</div>';

    rootNode.append(html);

    jm('.item').on('click', function () {
        var init = jm('.item.checked').length == 0;
        //防止同一个tab下重复提交
        if (jm(this).hasClass('checked')) {
            return;
        }
        jm('.item').removeClass('checked');
        jm(this).addClass('checked');
        if (jm(this).hasClass('all')) {
            selectType = 0;
            jm('.nav_container .underline').css('left', '2.2rem');
            if (!init) {
                logDog(200751, 4);
            }
        } else if (jm(this).hasClass('income')) {
            selectType = 1;
            jm('.nav_container .underline').css('left', '7.57rem');
            logDog(200751, 5);
        } else {
            selectType = 2;
            jm('.nav_container .underline').css('left', '12.9rem');
            logDog(200751, 6);
        }

        jm('.list').empty();
        page = 0;

        getInfo(page, selectType);
    });

    var page = 0,
        selectType = 0,
        maxPage = 0;

    jm('.all').click();

    function getInfo(page, type) {
        if (page <= maxPage) {
            getIntegralInfo(page, type).then((result) => {
                //防止回调时切换了其他tab,append到其他tab了
                if (type != selectType) {
                    return;
                }
                let rtData = result.rtData;
                jm('.credit_points').text(rtData.currentItg);
                maxPage = rtData.maxPage;
                var html = '';

                if (page == 0 && rtData.itgList.length == 0) {
                    jm('.no_itg').css('display', 'flex');
                } else {
                    jm('.no_itg').hide();
                }

                jm(rtData.itgList).each(function (i, o) {
                    var orderHref = preprocessUrl({ path: '', oldPath: '' }) + 'mdetail.jsp?id=' + o.orderId;
                    html += "<div class='itg_container'>";
                    if (o.type == 1) {
                        html += "<div class='a_itg a_itg_order' onclick='window.location.href=" + orderHref + "'>";
                        html += "<div class='left'>";
                        html += "<div class='top'>" + o.detail + '</div>';
                        html += "<div class='middle'>" + o.orderCode + '</div>';
                        html += "<div class='bottom'>" + o.createTime + '</div>';
                        html += '</div>';
                        if (o.operate == 0 || o.operate == 2) {
                            html += "<div class='right income'>" + o.alterNum + '</div>';
                        } else {
                            html += "<div class='right expenses'>" + o.alterNum + '</div>';
                        }
                        html += '</div>';
                    } else {
                        html += "<div class='a_itg'>";
                        html += "<div class='left'>";
                        html += "<div class='top'>" + o.detail + '</div>';
                        html += "<div class='bottom'>" + o.createTime + '</div>';
                        html += '</div>';
                        if (o.operate == 0 || o.operate == 2) {
                            html += "<div class='right income'>" + o.alterNum + '</div>';
                        } else {
                            html += "<div class='right expenses'>" + o.alterNum + '</div>';
                        }
                        html += '</div>';
                    }
                    html += '</div>';
                });
                if (page < maxPage && maxPage != 1) {
                    html += "<div class='loadMore'>点击加载更多</div>";
                }
                jm('.list').append(html);

                jm('.loadMore').on('click', function () {
                    if (page <= maxPage) {
                        page = page + 1;
                        getInfo(page, type);
                    }
                    jm(this).remove();
                });
            });
        }
    }
    jm('#itgRuleForward').click(function (event, historyCash) {
        jm('#modifyIntegralRule').find('.title').html(encodeHtml(itgRuleName));
        jm('#modifyIntegral').hide();
        jm('#modifyIntegralRule').show();
        changeBackgroundToWhite();
        cusHistory.add('itgRuleForward', historyCash, 1, '#itgRuleForward');
        logDog(201094, 14);
    });
    logDog(200751, 10);
};

// 主题3.0会员中心积分详情
export const loadItgV3 = function (mid, itgName, itgRuleName, showItgRuleBtn) {
    if (mid <= 0) {
        // location.href = 'login.jsp?errno=11';
        return;
    }
    // 打印基本结构
    var rootNode = jm('.itgPanel');

    var html =
        '' +
        "<div class='whole_top'>" +
        "<div class='top_container'>" +
        "<div class='widget'>" +
        itgName +
        '</div>' +
        "<div class='top_bar g_bgColor g_main_bgColor_v3'>" +
        "<div class='credit_points'></div>" +
        '</div>';
    if (showItgRuleBtn) {
        html += "<div id='itgRuleForward' class='itgRuleForward'>" + encodeHtml(itgRuleName) + '</div>';
    }
    html +=
        '</div>' +
        "<div class='nav_container'>" +
        "<div class='nav'>" +
        "<div class='all item'>" +
        LS.productScreenAll +
        '</div>' +
        "<div class='income item'>" +
        LS.itg_income +
        '</div>' +
        "<div class='spend item'>" +
        LS.itg_spend +
        '</div>' +
        '</div>' +
        "<div class='underline g_bgColor g_main_bgColor_v3'></div>" +
        '</div>' +
        '</div>' +
        "<div class='no_itg'>" +
        "<div class='img'></div>" +
        "<div class='txt'>" +
        LS.itg_notYet +
        ' ' +
        itgName +
        '~</div>' +
        '</div>' +
        "<div class='list'>" +
        '</div>';

    rootNode.append(html);

    jm('.item').on('click', function (init) {
        init = jm('.item.checked').length == 0;
        //防止同一个tab下重复提交
        if (jm(this).hasClass('checked')) {
            return;
        }
        jm('.item').removeClass('checked');
        jm(this).addClass('checked');
        if (jm(this).hasClass('all')) {
            selectType = 0;
            jm('.nav_container .underline').css('left', '2.1rem');
            if (!init) {
                logDog(200751, 4);
            }
        } else if (jm(this).hasClass('income')) {
            selectType = 1;
            jm('.nav_container .underline').css('left', '7.47rem');
            logDog(200751, 5);
        } else {
            selectType = 2;
            jm('.nav_container .underline').css('left', '12.8rem');
            logDog(200751, 6);
        }

        jm('.list').empty();
        page = 0;

        getInfo(page, selectType);
    });
    var page = 0,
        selectType = 0,
        maxPage = 0;

    jm('.all').click();

    function getInfo(page, type) {
        if (page <= maxPage) {
            getIntegralInfo(page, type).then((result) => {
                //防止回调时切换了其他tab,append到其他tab了
                if (type != selectType) {
                    return;
                }
                let rtData = result.rtData;
                jm('.credit_points').text(rtData.currentItg);
                maxPage = rtData.maxPage;
                var html = '';

                if (page == 0 && rtData.itgList.length == 0) {
                    jm('.no_itg').css('display', 'flex');
                } else {
                    jm('.no_itg').hide();
                }

                jm(rtData.itgList).each(function (i, o) {
                    var href = preprocessUrl({ path: '', oldPath: '' }) + 'mdetail.jsp?id=' + o.orderId;
                    html += "<div class='itg_container'>";
                    if (o.type == 1) {
                        html += "<div class='a_itg a_itg_order' onclick='window.location.href=" + href + "'>";
                        html += "<div class='left'>";
                        html += "<div class='top'>" + o.detail + '</div>';
                        html += "<div class='middle'>" + o.orderCode + '</div>';
                        html += "<div class='bottom'>" + o.createTime + '</div>';
                        html += '</div>';
                        if (o.operate == 0 || o.operate == 2) {
                            html += "<div class='right income'>" + o.alterNum + '</div>';
                        } else {
                            html += "<div class='right expenses'>" + o.alterNum + '</div>';
                        }
                        html += '</div>';
                    } else {
                        html += "<div class='a_itg'>";
                        html += "<div class='left'>";
                        html += "<div class='top'>" + o.detail + '</div>';
                        html += "<div class='bottom'>" + o.createTime + '</div>';
                        html += '</div>';
                        if (o.operate == 0 || o.operate == 2) {
                            html += "<div class='right income'>" + o.alterNum + '</div>';
                        } else {
                            html += "<div class='right expenses'>" + o.alterNum + '</div>';
                        }
                        html += '</div>';
                    }
                    html += '</div>';
                });
                if (page < maxPage && maxPage != 1) {
                    html += "<div class='loadMore'>点击加载更多</div>";
                }
                jm('.list').append(html);

                jm('.loadMore').on('click', function () {
                    if (page <= maxPage) {
                        page = page + 1;
                        getInfo(page, type);
                    }
                    jm(this).remove();
                });
            });
        }
    }

    jm('#itgRuleForward').click(function (event, historyCash) {
        jm('#modifyIntegralRule').find('.title').html(encodeHtml(itgRuleName));
        jm('#modifyIntegral').hide();
        jm('#modifyIntegralRule').show();
        changeBackgroundToWhite();
        cusHistory.add('itgRuleForward', historyCash, 1, '#itgRuleForward');
    });
    logDog(200751, 10);
};

export const initMemberHeadPic = (options) => {
    var img = jm('#memberHeadPic'),
        icon = jm('.icon-pnameIcon');
    if (typeof options.path != 'undefined') {
        img.attr('src', options.path);
    }
    if (options.width != 0) {
        var scale = options.width / icon.width(),
            marginTop = -(options.top / scale) + 'px',
            marginLeft = -options.left / scale + 'px';
        img.css({
            width: options.imgW / scale + 'px',
            height: options.imgH / scale + 'px',
            marginTop,
            marginLeft,
        });
        //如果是默认头像会width=240,走进这里，然后width和height都被置为0,导致点击无效
        if (options.imgW / scale == 0 || options.imgH / scale == 0) {
            img.removeAttr('style');
        }
    }
};
